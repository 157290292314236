
import { defineComponent } from 'vue';
import UserTable from '@/components/UserTable.vue';

export default defineComponent({
                                 name: 'Users',
                                 components: {
                                   'user-table': UserTable
                                 }
                               });
