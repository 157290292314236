<template>
  <div class="user-table">
    <div class="table-head">
      <div class="table-row">
        <div v-for="field in tableHead" :key="field" class="table-column">
          <div v-if="field.sortable" class="column-sortable" @click="sort(field.name)">
            <div class="column-sortable-icons">
              <img v-if="currentSort.field !== field.name" src="/images/arrows.png"/>
              <img v-if="currentSort.field === field.name && currentSort.direction === 'asc'"
                   src="/images/arrows_asc.png"/>
              <img v-if="currentSort.field === field.name && currentSort.direction === 'desc'"
                   src="/images/arrows_desc.png"/>
            </div>
            <div class="column-title">{{ field.text }}</div>
          </div>
          <div v-else class="column">
            <div class="column-title">{{ field.text }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-body">
      <div v-for="user in users" :key="user" class="table-row">
        <div class="table-column email">{{ user.name }}</div>
        <div class="table-column name"><img alt="" src="/images/user2.png"> <span>{{ user.email }}</span></div>
        <div class="table-column password">
          <div v-if="user._id !== currentPasswordUserId" class="edit-password">
            <img src="/images/edit.svg" @click="changePassword(user._id)"/> ********
          </div>
          <div v-if="user._id === currentPasswordUserId" class="save-password">
            <img src="/images/cancel_s.png" style="margin-right: 5px;" @click="cancelPasswordChange()"/>
            <img src="/images/ok_password.png" @click="saveUserPassword(user)"/>
            <input v-model="userPassword" name="password" type="text"/>
          </div>
        </div>
        <div class="table-column status">
          <div v-if="user.status === 1" class="tooltip">
            <img src="/images/user_active.png" @click="changeUserStatus(user, 2)"/>
            <div class="top">
              <p>Deaktivieren</p>
              <i></i>
            </div>
          </div>
          <div v-if="user.status === 2" class="tooltip">
            <img src="/images/user_inactive.png" @click="changeUserStatus(user, 1)"/>
            <div class="top">
              <p>Aktivieren</p>
              <i></i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="pages > 1" class="table-paging">
      <div v-if="selectedPage > 3" class="first-page" @click="changePage(1)">&lt;&lt;</div>
      <div v-if="selectedPage - 1 > 0" class="prev-page" @click="changePage(selectedPage - 1)">&lt;</div>
      <div v-if="selectedPage > 2" class="page" @click="changePage(selectedPage - 2)">{{ selectedPage - 2 }}</div>
      <div v-if="selectedPage > 1" class="page" @click="changePage(selectedPage - 1)">{{ selectedPage - 1 }}</div>
      <div class="page active">{{ selectedPage }}</div>
      <div v-if="selectedPage + 1 <= pages" class="page" @click="changePage(selectedPage + 1)">{{
          selectedPage + 1
        }}
      </div>
      <div v-if="selectedPage + 2 <= pages" class="page" @click="changePage(selectedPage + 2)">{{
          selectedPage + 2
        }}
      </div>
      <div v-if="selectedPage + 1 <= pages" class="next-page" @click="changePage(selectedPage + 1)">&gt;</div>
      <div v-if="pages > 3" class="last-page" @click="changePage(pages)">&gt;&gt;</div>
    </div>
  </div>

</template>

<script>
import * as _ from 'lodash';

export default {
  name: 'UserTable',
  data() {
    return {
      columns: [
        {name: 'name', text: 'Name', sortable: true},
        {name: 'email', text: 'Login', sortable: true},
        {name: 'password', text: 'Passwort'},
        {name: 'status', text: 'Aktionen', sortable: true}
      ],
      entries: [],
      filteredEntries: [],
      currentPage: 1,
      allPages: 1,
      perPage: 15,
      currentSort: {
        field: '',
        direction: ''
      },
      editPassword: false,
      currentPasswordUserId: '',
      userPassword: '',
    }
  },
  computed: {
    tableHead() {
      return this.columns;
    },
    users() {
      return this.filteredEntries;
    },
    pages() {
      return this.allPages;
    },
    selectedPage() {
      return this.currentPage;
    }
  },
  created() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      this.$store.dispatch('getUsers')
          .then((response) => {
            this.entries = response.data;
            this.allPages = Math.ceil(response.data.length / this.perPage);
            this.updateFilteredUsers();
          })
          .catch((error) => {
            console.log(error);
          });
    },
    changeUserStatus(user, status) {
      const formData = {
        _id: user._id,
        name: user.name,
        email: user.email,
        status: status,
      };
      this.$store.dispatch('saveUser', formData).then(() => {
        this.getUsers();
        this.updateFilteredUsers();
      }).catch((error) => {
        console.log(error);
      });
    },
    changePassword(id) {
      this.currentPasswordUserId = id;
      this.editPassword = true;
      this.userPassword = '';
    },
    saveUserPassword(user) {
      if (this.userPassword.length > 7) {
        const formData = {
          _id: user._id,
          name: user.name,
          email: user.email,
          password: this.userPassword,
          status: user.status,
        };
        this.$store.dispatch('saveUser', formData).then(() => {
          this.currentPasswordUserId = '';
          this.editPassword = false;
          this.userPassword = '';
        }).catch((error) => {
          console.log(error);
        });
      } else {
        alert('Die passwortlänge muss mindestens 8 symbole betragen');
        this.currentPasswordUserId = '';
        this.editPassword = false;
        this.userPassword = '';
      }
    },
    cancelPasswordChange() {
      this.currentPasswordUserId = '';
      this.editPassword = false;
      this.userPassword = '';
    },
    sort(name) {
      let direction = 'asc';
      if (name === this.currentSort.field && this.currentSort.direction === 'asc') {
        direction = 'desc';
      }

      this.currentSort = {
        field: name,
        direction: direction
      };
      // move to first page
      this.currentPage = 1;
      this.updateFilteredUsers();
    },
    changePage(page) {
      this.currentPage = page;
      this.updateFilteredUsers();
    },
    updateFilteredUsers() {
      const limit = this.perPage;
      const offset = (this.currentPage - 1) * this.perPage;

      if (this.currentSort.field.length > 0 && this.currentSort.direction.length > 0) {
        if (this.currentSort.field === 'status') {
          this.entries = _.orderBy(this.entries, (a) => a[this.currentSort.field], [this.currentSort.direction]);
        } else {
          this.entries = _.orderBy(this.entries, (a) => a[this.currentSort.field].toLowerCase(), [this.currentSort.direction]);
        }
      }

      this.filteredEntries = this.entries.slice(offset, limit + offset);
    }
  }
}
</script>

<style lang="scss">
.user-table {
  margin: 35px 0;

  .table-head, .table-body {
    width: 100%;

    .table-row {
      display: grid;
      grid-template-columns: 30% 30% 25% 15%;
      align-items: center;
    }
  }

  .table-head {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #022259;
    text-align: left;
    border: 2px solid #EFF9FF;

    .table-row {

      .table-column {

        .column-sortable {
          display: grid;
          grid-template-columns: 20px auto;
          align-self: center;
          align-items: center;
          padding: 10px;

          &:hover {
            cursor: pointer;
          }

          .column-sortable-icons {
            img {
              position: relative;
              top: -2px;
            }
          }
        }

        .column {
          padding: 10px;
        }
      }
    }
  }

  .table-body {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    color: #000000;

    .table-row {
      min-height: 60px;

      &:nth-child(odd) {
        background: #EFF9FF;
      }

      .table-column {
        padding: 0 10px;

        &.name {
          span {
            padding-left: 10px;
            position: relative;
            top: 1px;
          }
        }

        &.password {
          .edit-password {
            img {
              padding-right: 10px;

              &:hover {
                cursor: pointer;
              }
            }
          }

          .save-password {
            img {
              &:hover {
                cursor: pointer;
              }
            }

            input {
              max-width: 80%;
              height: 30px;
              background: #FFFFFF;
              border: 1px solid #022259;
              box-sizing: border-box;
              border-radius: 11px;
              padding-left: 10px;
              margin-left: 5px;
            }
          }
        }

        &.status {
          .tooltip {
            display: inline-block;
            position: relative;
            border-bottom: 1px dotted #666;
            text-align: left;
          }

          .tooltip .top {
            min-width: 100px;
            top: -20px;
            left: 50%;
            transform: translate(-50%, -100%);
            padding: 10px 20px;
            color: #022259;
            background-color: #ffffff;
            font-weight: normal;
            font-size: 13px;
            border-radius: 8px;
            position: absolute;
            z-index: 99999999;
            box-sizing: border-box;
            border: 1px solid #022259;
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
            display: none;
          }

          .tooltip:hover .top {
            display: block;
          }

          .tooltip .top i {
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -12px;
            width: 24px;
            height: 12px;
            overflow: hidden;
          }

          .tooltip .top i::after {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            background-color: #ffffff;
            border: 1px solid #022259;
            box-shadow: 0 1px 8px rgba(0, 0, 0, 0.5);
          }

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .table-paging {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto auto;
    justify-content: right;
    align-items: center;
    margin-top: 25px;

    .first-page, .prev-page, .next-page, .last-page {
      background: #022259;
      border-radius: 15px;
      padding: 7px;
      color: #ffffff;
      width: 15px;
      text-align: center;
      margin: 0 7px;

      &:hover {
        cursor: pointer;
      }
    }

    .page {
      width: 15px;
      color: #022259;
      margin: 0 7px;

      &:hover {
        cursor: pointer;
      }

      &.active {
        font-weight: bold;

        &:hover {
          cursor: default;
        }
      }
    }
  }
}
</style>
